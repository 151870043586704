import { AdminModuleActions, DrawingsModuleActions, QuoteModuleActions } from '@core/services/cerbos/cerbos.policies';

export const MiRoutes = {
	login: {
		path: 'login',
		url: '/login',
		preauth: {
			path: 'preauth',
			url: '/login/preauth'
		}
	},
	dashboard: {
		path: 'dashboard',
		url: '/dashboard'
	},
	quotes: {
		path: 'quotes',
		url: '/quotes'
	},
	drawings: {
		path: 'drawings',
		url: '/drawings',
		children: {
			drawingUpload: 'drawing-upload',
			recentlyUploadedDrawings: 'recently-uploaded-drawings',
			createPart: 'create-part',
			matchingResults: 'matching-results',
			partAttributes: 'part-attributes'
		}
	},
	// administration: {
	// 	path: 'administration',
	// 	url: '/administration'
	// },
	helpCenter: {
		path: 'help-center',
		url: '/help-center'
	},
	admin: {
		path: 'admin',
		url: '/admin'
	},
	noPermissions: {
		path: 'forbidden',
		url: '/forbidden'
	}
};

export const RouteNavLinksConfig = [
	{
		id: 'my-quotes',
		title: 'My Quotes',
		url: '/quotes',
		active: false,
		cerbosAction: QuoteModuleActions.Access
	},
	{
		id: 'my-drawings',
		title: 'My Drawings',
		url: '/drawings',
		active: false,
		cerbosAction: DrawingsModuleActions.Access
	},
	{
		id: 'administartion',
		title: 'Administration ',
		url: '/admin',
		active: false,
		cerbosAction: AdminModuleActions.Access
	}
];

export const MiHomeRoute = MiRoutes.dashboard.url;
